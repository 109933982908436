import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { useComponentContext } from 'src/components/layout';
import { translations } from './MediaControls.translations';

import {
  defaultStyles_,
  container_,
  buttonWrapper_,
  button_,
  icon_,
  darkTheme_,
} from './MediaControls.module.scss';

const Play = ({ size = '30', className = '' }) => (
  <svg
    className={className}
    height={size}
    role="img"
    version="1.1"
    viewBox="0 0 512 512"
    width={size}
    x="0"
    xmlns="http://www.w3.org/2000/svg"
    y="0"
    aria-hidden="true"
  >
    <title>Play</title>
    <path d="M392.033,243.01L199.232,131.697c-4.641-2.68-10.359-2.68-15,0c-4.641,2.679-7.5,7.631-7.5,12.99v222.621    c0,5.359,2.859,10.311,7.5,12.99c2.32,1.34,4.91,2.01,7.5,2.01c2.59,0,5.18-0.67,7.5-2.009L392.033,268.99    c4.641-2.68,7.5-7.632,7.5-12.991C399.533,250.641,396.674,245.689,392.033,243.01z M206.732,341.329V170.668L354.532,256    L206.732,341.329z" />
  </svg>
);

const Pause = ({ size = '30', className = '' }) => (
  <svg
    className={className}
    height={size}
    role="img"
    version="1.1"
    viewBox="0 0 512 512"
    width={size}
    x="0"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0"
    aria-hidden="true"
  >
    <title>Pause</title>
    <path d="M304.199,137.723c-8.284,0-15,6.716-15,15V359.28c0,8.284,6.716,15,15,15s15-6.716,15-15V152.723    C319.199,144.439,312.483,137.723,304.199,137.723z" />
    <path d="M207.799,137.723c-8.284,0-15,6.716-15,15V359.28c0,8.284,6.716,15,15,15s15-6.716,15-15V152.723    C222.799,144.439,216.083,137.723,207.799,137.723z" />
  </svg>
);

const MediaControls = forwardRef(function MediaControls(
  { className = defaultStyles_, onPlay, onPause, isPaused = true, playLabel, pauseLabel, style = {} },
  ref
) {
  const { shouldUseDarkTheme } = useComponentContext();

  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);

  const defaultPlayLabel = useTranslation('media_controls::play');
  const defaultPauseLabel = useTranslation('media_controls::pause');
  const getAriaLabel = () =>
    (isPaused ? playLabel ?? defaultPlayLabel : pauseLabel ?? defaultPauseLabel);

  return (
    <div
      data-testid="media-controls"
      ref={ref}
      style={style}
      className={classNames(container_, className)}
    >
      <div className={buttonWrapper_}>
        <button
          aria-label={getAriaLabel()}
          className={classNames(button_, shouldUseDarkTheme && darkTheme_)}
          onClick={isPaused ? onPlay : onPause}
          type="button"
        >
          {isPaused ? <Play className={icon_} size="35" /> : <Pause className={icon_} size="35" />}
        </button>
      </div>
    </div>
  );
});

MediaControls.propTypes = {
  className: PropTypes.string,
  onPlay: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  isPaused: PropTypes.bool,
  playLabel: PropTypes.string,
  pauseLabel: PropTypes.string,
  style: PropTypes.shape({}),
};

export { MediaControls };
