'use client';

import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Accordion } from 'src/tapestry/core/accordion';
import { toFieldPath } from '@stackbit/annotations';
import { classNames } from 'src/utils/css';
import { ComponentContextProvider, useHeaderContext } from 'src/components/layout';
import { Background } from 'src/components/common/Background';
import { bannerHeightDesktop, colorSandBase } from 'src/styles/exports.module.scss';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { useUserAttributesContext } from 'src/components/contexts/UserAttributesContext';
import {
  DismissibleContextProvider,
  useDismissibleContext,
} from 'src/components/contexts/DismissibleContext';
import { COMPONENT_NAMES, EVENT_NAMES } from 'src/services/analytics-service';
import { FluidText } from 'src/components/common/FluidText';
import { Close } from 'src/components/common/Icons';
import {
  banner_,
  content_,
  textContentContainer_,
  textContentContainerCenter_,
  textContent_,
  closeButton_,
} from './NotificationBanner.module.scss';
import translationContent from './NotificationBanner.translations';

const NotificationBannerContent = ({ children, className, textAlign, background, entryId }) => {
  const { id, isOpen, closeHandler } = useDismissibleContext();
  const { appendTranslationKeys } = useTranslationContext();
  const { isMobileNavOpen, hasScrolledPastFirstSection } = useHeaderContext();
  const { isMobile } = useUserAttributesContext();

  appendTranslationKeys(translationContent);
  const closeLabel = useTranslation('notification_banner::close');

  /*
   * Logic for handling the screen height available
   */

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.setProperty('--banner-height-desktop-px', bannerHeightDesktop);
    } else {
      document.documentElement.style.setProperty('--banner-height-desktop-px', '0px');
    }

    // Reset banner height when navigating away
    return () => {
      document.documentElement.style.setProperty('--banner-height-desktop-px', '0px');
    };
  }, [isOpen]);

  return (
    <Accordion
      id={`accordion-${id}`}
      isOpen={isMobileNavOpen || hasScrolledPastFirstSection ? false : isOpen}
    >
      <Background
        as="div"
        data-testid="notification-banner"
        className={classNames(banner_, className)}
        background={background || colorSandBase}
      >
        <div className={classNames(content_)}>
          <div
            className={classNames(
              textContentContainer_,
              textAlign === 'center' && textContentContainerCenter_
            )}
          >
            <FluidText
              type="div"
              className={textContent_}
              min="ws-text-md-medium"
              max="ws-text-lg-medium"
              {...(entryId ? toFieldPath({ objectId: entryId, fieldPath: 'body' }) : {})}
            >
              {children}
            </FluidText>
          </div>
          <button type="button" className={classNames(closeButton_)} onClick={closeHandler}>
            <span className="visually-hidden">{closeLabel}</span>
            <Close size={isMobile ? 'sm' : 'md'} aria-hidden="true" />
          </button>
        </div>
      </Background>
    </Accordion>
  );
};

export const NotificationBanner = ({ id, children, ...rest }) => {
  if (!id) return null;

  return (
    <ComponentContextProvider value={{ name: COMPONENT_NAMES.NOTIFICATION_BANNER }}>
      <DismissibleContextProvider
        id={id}
        componentName="NotificationBanner"
        displayedEvent={EVENT_NAMES.BANNER_DISPLAYED}
        dismissedEvent={EVENT_NAMES.BANNER_DISMISSED}
      >
        <NotificationBannerContent {...rest}>{children}</NotificationBannerContent>
      </DismissibleContextProvider>
    </ComponentContextProvider>
  );
};

NotificationBanner.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  textAlign: PropTypes.oneOf(['center', 'default']),
  background: PropTypes.string,
  entryId: PropTypes.string,
};
