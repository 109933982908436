import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { Markdown } from 'src/tapestry/core/markdown';
import { toFieldPath } from '@stackbit/annotations';
import { classNames } from 'src/utils/css';
import { GRID_DEFAULT_CONFIG, useComponentContext } from 'src/components/layout';
import { FluidText } from 'src/components/common/FluidText';
import {
  wrapper_,
  mediaWrapper_,
  captionWrapper_,
  bleedLeft_,
  bleedRight_,
} from './MediaWithCaption.module.scss';

const captionRenderers = {
  paragraph: ({ children }) => (
    <FluidText type="p" all="ws-eyebrow-sm" uppercase>
      {children}
    </FluidText>
  ),
};

export const BLEED_CLASSES = {
  left: bleedLeft_,
  right: bleedRight_,
};

export const MediaWithCaption = ({ media, caption, bleed, className, ...rest }) => {
  const captionContent = () => {
    if (isString(caption)) return <Markdown source={caption} renderers={captionRenderers} />;
    return caption;
  };

  // We want the parent (section) entryId for the caption annotation
  const { entryId } = useComponentContext();

  const renderCaptionAnnotation = entryId
    ? toFieldPath({ objectId: entryId, fieldPath: 'mediaCaption' })
    : {};

  return (
    <figure className={classNames(wrapper_, BLEED_CLASSES[bleed], className)} {...rest}>
      <div className={classNames(mediaWrapper_)} style={{ '--grid-gap': GRID_DEFAULT_CONFIG.gap }}>
        {media}
      </div>
      <figcaption className={classNames(captionWrapper_)} {...renderCaptionAnnotation}>
        {captionContent()}
      </figcaption>
    </figure>
  );
};

MediaWithCaption.propTypes = {
  media: PropTypes.node.isRequired,
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  bleed: PropTypes.oneOf(Object.keys(BLEED_CLASSES)),
  entryId: PropTypes.string,
};
